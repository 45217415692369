"use strict";

// tohle nahraje i soubory, ktere nejsou primo linkovane z entrypointu
import "./img/*.{png,jpg,jpeg,gif,svg}";

// UIkit
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";

// loads the Icon plugin
UIkit.use(Icons);

// import stylu
import "./../sass/index.scss";

// tiny slider
import { tns } from "tiny-slider/src/tiny-slider";

//menu
//MENU OPEN
var opener = document.getElementById("collapseToggle");
if (opener) {
	opener.onclick = function () {
		menuManipulate();
	};

	var submenuCloserButton = document.getElementsByClassName("submenuS");

	for (var i = 0; i < submenuCloserButton.length; i++) {
		submenuCloserButton[i].addEventListener("click", menuClose, false);
	}
}

function menuManipulate() {
	const openQ = document.querySelector("#collapse");
	if (openQ.classList.contains("open")) {
		//console.log("otevřeno");
		openQ.classList.remove("open");
	} else {
		//console.log("zavřeno");
		openQ.classList.add("open");
	}
}

function menuClose() {
	const openQ = document.querySelector("#collapse");
	openQ.classList.remove("open");
}
window.onload = function () {
	var tnsTest = document.getElementById("HPCarousel");
	if (tnsTest) {
		var slider = tns({
			container: "#hpslider",
			items: 1,
			center: true,
			controls: true,
			nav: false,
			mouseDrag: true,
			fixedWidth: false,
			autoHeight: true,
			slideBy: "1",
			autoplay: true,
			speed: 2000,
			autoplayButton: false,
			autoplayTimeout: 5000,
		});
	}
};

//LIGHTBOX OPEN
var lightboxBackcolor = "rgba(99, 22, 75, 0.8)";
var lightboxOpener = document.getElementsByClassName("lightboxOpener");
for (var i = 0; i < lightboxOpener.length; i++) {
	lightboxOpener[i].addEventListener("click", openLightbox, false);
}

function openLightbox(e) {
	e.preventDefault();
	var LB = document.getElementById(this.getAttribute("data-lightbox"));
	lightboxBackcolor = this.getAttribute("data-color");
	UIkit.lightbox(LB, "");
}

UIkit.util.on(document, "shown", ".uk-lightbox.uk-open", function (e) {
	//console.log("ok LB / " + lightboxBackcolor);
	var lightBoxBack = document.getElementsByClassName("uk-lightbox");
	for (var i = 0; i < lightBoxBack.length; i++) {
		lightBoxBack[i].style.backgroundColor = "rgba(" + lightboxBackcolor + ")";
	}
});

//FADEIN BOX
var startBlackBox = document.getElementById("startBlackBox");
// Code for Chrome, Safari and Opera
startBlackBox.addEventListener("webkitAnimationEnd", startBlackFadeOut);
// Standard syntax
startBlackBox.addEventListener("animationend", startBlackFadeOut);
function startBlackFadeOut() {
	startBlackBox.remove();
}

//FADEOUT LINK

var fadeOutOpener = document.getElementsByClassName("fadeOutOpener");
var fadeOutLink = "";

for (var i = 0; i < fadeOutOpener.length; i++) {
	fadeOutOpener[i].addEventListener("click", fadeOutRun, false);
}

function fadeOutRun(e) {
	e.preventDefault();
	fadeOutLink = window.location.origin + "/" + this.getAttribute("href");

	//console.log("kliknuto - " + fadeOutLink);
	var leaveBlackBox = document.getElementById("leaveBlackBox");
	leaveBlackBox.addEventListener("webkitAnimationEnd", fadeOutLeave);
	leaveBlackBox.addEventListener("animationend", fadeOutLeave);
	leaveBlackBox.classList.add("leaveBlackBox");
}
function fadeOutLeave() {
	//console.log("konec animace - " + fadeOutLink);
	window.location.href = fadeOutLink;
}
window.addEventListener(
	"pageshow",
	function () {
		var leaveBlackBoxL = document.getElementById("leaveBlackBox");
		leaveBlackBoxL.classList.remove("leaveBlackBox");
	},
	false
);

//PARALAX
import "intersection-observer";

const paralaxObserver = new IntersectionObserver((entries) => {
	entries.forEach((entry) => {
		if (entry.isIntersecting) {
			entry.target.classList.add(entry.target.dataset.paralax);
			paralaxObserver.unobserve(entry.target);
		}
	});
});

document.addEventListener("DOMContentLoaded", () => {
	document.querySelectorAll(".paraX").forEach((elm) => {
		paralaxObserver.observe(elm);
	});
});

//CAROUSEL
//carousel = document.querySelector("#carousel1");
//console.log(carousel.offsetWidth);
let carousel = function (idobject) {
	this.speed = 2; //aktuální rychlost
	this.maxSpeed = 20; //maximální rychlost nastaitelná myší a touchem - je to v procentech (/ 100%)
	this.speedStart = this.speed; //ryhclost při startu
	this.offset = 0; //posun při startu
	this.carousel = document.querySelector(idobject);
	this.carouselName = idobject;
	this.carouselMouseDetection = document.querySelector(
		idobject + "--mouseDetection"
	);
	this.widthItems = 0;
	this.timer;
	let context = this;

	this.carouselMouseDetection.onmousemove = function (e) {
		let maxW = context.carouselMouseDetection.offsetWidth;
		let leftComp = Math.round((window.innerWidth - maxW) / 2);
		let procentOff = 0;
		let mX = e.clientX - leftComp;

		if (mX < 0) mX = 0;
		if (mX > maxW) mX = maxW;

		const percentPos = Math.round((mX / maxW) * 100);
		//console.log("procent = " + percentPos);

		if (percentPos < 25) {
			//nulová rychlost
			//console.log("nulová rychlost");
			mX = 0;
			procentOff = (context.maxSpeed * mX) / maxW;
			procentOff = Math.round(procentOff * 100) / 100;
		} else if (percentPos >= 25 && percentPos < 55) {
			//zpomaluje - procentOff je od 0 do 2
			//console.log("zpomaluje");
			procentOff = 0.5;
			procentOff = Math.round(procentOff * 100) / 100;
		} else if (percentPos >= 55 && percentPos < 70) {
			//zpomaluje - základní rychlost
			//console.log("záklandí rychlost");
			procentOff = 2;
			procentOff = Math.round(procentOff * 100) / 100;
		} else {
			//zrychluje - procentOff je od 2 do 20
			procentOff = (context.maxSpeed * mX) / maxW;
			procentOff = Math.round(procentOff * 100) / 100;
		}

		//console.log("procentOff = " + procentOff);
		context.speed = Math.round(context.speedStart * procentOff);
	};
	this.carouselMouseDetection.onmouseout = function () {
		context.speed = context.speedStart;
	};

	this.carouselMouseDetection.addEventListener("touchmove", function (e) {
		let maxW = context.carouselMouseDetection.offsetWidth;
		let leftComp = Math.round((window.innerWidth - maxW) / 2);
		let procentOff = 0;
		let mX = e.touches[0].clientX - leftComp;

		if (mX < 50) mX = 0;
		if (mX > maxW) mX = maxW;

		const percentPos = Math.round((mX / maxW) * 100);
		//console.log("procent = " + percentPos);

		if (percentPos < 25) {
			//nulová rychlost
			//console.log("nulová rychlost");
			mX = 0;
			procentOff = (context.maxSpeed * mX) / maxW;
			procentOff = Math.round(procentOff * 100) / 100;
		} else if (percentPos >= 25 && percentPos < 55) {
			//zpomaluje - procentOff je od 0 do 2
			//console.log("zpomaluje");
			procentOff = 0.5;
			procentOff = Math.round(procentOff * 100) / 100;
		} else if (percentPos >= 55 && percentPos < 70) {
			//zpomaluje - základní rychlost
			//console.log("záklandí rychlost");
			procentOff = 2;
			procentOff = Math.round(procentOff * 100) / 100;
		} else {
			//zrychluje - procentOff je od 2 do 20
			procentOff = (context.maxSpeed * mX) / maxW;
			procentOff = Math.round(procentOff * 100) / 100;
		}

		context.speed = Math.round(context.speedStart * procentOff);
	});
	this.carouselMouseDetection.addEventListener("touchend", function () {
		context.speed = context.speedStart;
	});
};

carousel.prototype.init = function () {
	//get width;
	let context = this;
	let items = this.carousel.getElementsByClassName("ich-speedcarousel--item");
	let max = items.length;
	for (let i = 0; i < max; i++) {
		this.widthItems += items.item(i).offsetWidth;
		var cln = items.item(i).cloneNode(true);
		cln.classList.remove("ich-speedcarousel--item");
		cln.classList.add("ich-speedcarousel--duplication");
		this.carousel.appendChild(cln);
	}
	clearInterval(this.timer);
	this.timer = setInterval(function () {
		context.move();
	}, 50);
	//console.log("iniciace -> " + this.widthItems);
};

carousel.prototype.move = function (e) {
	if (this.offset + this.speed >= this.widthItems) this.offset = 0;
	else this.offset += this.speed;
	this.carousel.style.transform = "translateX(-" + this.offset + "px)";
	//console.log("pohyb");
};

window.onload = function () {
	if (document.getElementById("carousel1")) {
		let carousel1w = new carousel("#carousel1");
		carousel1w.init();
	}
	if (document.getElementById("carousel2")) {
		let carousel2w = new carousel("#carousel2");
		carousel2w.init();
	}
};
window.onresize = function () {
	if (document.getElementById("carousel1")) carousel1w.init();
	if (document.getElementById("carousel2")) carousel2w.init();
};
